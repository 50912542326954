import { useContext } from 'react'

import { RewardsContext } from '@/contexts/RewardsContext'
import { GuestRewardPrice } from '@/gql/graphql'

export const useRewardItemDetails = (rewardPrice?: GuestRewardPrice | null) => {
  const {
    balance,
    noun,
    flags: { rewardsPriceEnabled },
  } = useContext(RewardsContext)

  const isRewardsPriceTagVisible =
    rewardsPriceEnabled && !!rewardPrice?.originalAmountInCents

  const rewardPriceInPoints = (rewardPrice?.originalAmountInCents || 0) / 100
  const rewardPriceLessThanBalance =
    rewardsPriceEnabled && rewardPriceInPoints <= (balance || 0)
  const isRewardAffordable = rewardsPriceEnabled
    ? rewardPriceLessThanBalance
    : true

  return {
    rewardsPriceEnabled,
    isRewardsPriceTagVisible,
    isRewardAffordable,
    rewardPriceInPoints,
    noun,
    balance,
  }
}
