import {
  isRenderableMenuItem,
  RenderableMenuItem,
} from '@/components/MenuItem/utils'
import {
  CartPartsFragment,
  CartUpsellInput,
  MenuCategoriesQuery,
} from '@/gql/graphql'
import { UpsellGroupPartsFragment, UpsellPartsFragment } from '@/lib/gql.types'

type CartItem = CartPartsFragment['cartItems'][0]

type UpsellItem = UpsellPartsFragment & {
  isDynamic: boolean
  hasModifiers: boolean
}

export const cartItemSerializer = (cartItem: CartItem) => ({
  id: cartItem.id,
  type: cartItem.type,
  name: cartItem.name,
  price: cartItem.totalInCents,
  quantity: cartItem.quantity,
  upsells: cartItem.cartUpsells?.map((upsell) => ({
    id: upsell.id,
    name: upsell.name,
    quantity: upsell.quantity,
  })),
  hasImage: !!cartItem.item?.cdnImage,
  displayPrice: cartItem.item?.priceData.displayPrice,
  displayPriceHasDollar: cartItem.item?.priceData.displayPrice?.includes('$'),
  displayPriceHasDecimal: cartItem.item?.priceData.displayPrice?.includes('.'),
  displayPriceHasZero:
    cartItem.item?.priceData.displayPrice?.includes('.') &&
    cartItem.item?.priceData.displayPrice?.charAt(-1) === '0',
  hasPriceOverride: cartItem.item?.priceData.hasPriceOverride,
  hasPopularTag: cartItem.item?.isPopular,
  hasDescription: (cartItem.item?.description?.length || 0) > 0,
  descriptionLength: cartItem.item?.description?.length || 0,
  dietaryTags: cartItem.item?.dietaryTags,
  hasDietaryTags: cartItem.item?.dietaryTags?.length || 0,
  filterTags: cartItem.item?.filterTags,
  hasFilterTags: cartItem.item?.filterTags?.length || 0,
  hasModifiers: cartItem.cartModifiers && cartItem.cartModifiers.length > 0,
})

export const menuItemSerializer = (menuItem: RenderableMenuItem) => {
  const serializedMenuItem = {
    id: menuItem.id,
    venueSlug: menuItem.slug,
    name: menuItem.name,
    price: menuItem.priceData.priceInCents,
    displayPrice: menuItem.priceData.displayPrice,
    displayPriceHasDollar:
      menuItem.priceData.displayPrice?.includes('$') || false,
    displayPriceHasDecimal:
      menuItem.priceData.displayPrice?.includes('.') || false,
    displayPriceHasZero:
      menuItem.priceData.displayPrice?.includes('.') &&
      menuItem.priceData.displayPrice?.charAt(-1) === '0',
    hasPriceOverride: menuItem.priceData.hasPriceOverride,
    hasImage: !!menuItem.image,
  }

  if (isRenderableMenuItem(menuItem)) {
    return {
      ...serializedMenuItem,
      hasPopularTag: menuItem.isPopular,
      hasDescription: (menuItem.description?.length || 0) > 0,
      descriptionLength: menuItem.description?.length || 0,
      dietaryTags: menuItem.dietaryTags,
      hasDietaryTags: menuItem.dietaryTags?.length || 0,
      filterTags: menuItem.filterTags,
      hasFilterTags: menuItem.filterTags?.length || 0,
    }
  }

  return serializedMenuItem
}

export const menuCategorySerializer = (
  menuCategory: MenuCategoriesQuery['guestMenuCategories'][0],
) => ({
  id: menuCategory.id,
  slug: menuCategory.slug,
  name: menuCategory.name,
  menuCategoryType: menuCategory.menuCategoryType,
  orderingType: menuCategory.orderingType,
})

export const transformUpsellsForTracking = (
  groups: UpsellGroupPartsFragment[],
  cartItemInputs: CartUpsellInput[],
) => {
  const cartItemInputsIds = cartItemInputs.map(
    (cartItemInput) => cartItemInput.id,
  )
  const upsellItems: UpsellItem[] = []

  groups.forEach((group) => {
    group.upsells.forEach((upsell) => {
      const upsellItemInput = cartItemInputs.find(
        (input) => input.id === upsell.id,
      )

      if (group.respectCartQuantity) {
        return
      }

      upsellItems.push({
        ...upsell,
        isDynamic: group.isDynamic ?? false,
        hasModifiers:
          upsellItemInput?.modifiers && upsellItemInput.modifiers.length > 0
            ? true
            : false,
      })
    })
  })

  const filteredUpsells = upsellItems.filter((upsell) => {
    return cartItemInputsIds.includes(upsell.id)
  })

  return filteredUpsells.map((upsell) => {
    return {
      id: upsell.id,
      name: upsell.name,
      price: upsell.menuItem.priceData.priceInCents,
      hasPriceOverride: upsell.menuItem.priceData.hasPriceOverride,
      showFullPrice: upsell.showFullPrice,
      isDynamic: upsell.isDynamic,
      hasModifiers: upsell.hasModifiers,
    }
  })
}
