import { ValidLanguageFilters } from '@/components/Lang/utils'
import {
  GuestMenuItem,
  GuestUpsellGroup,
  MenuItemDetailsFragment,
} from '@/gql/graphql'
import { MenuItemPartsFragment } from '@/lib/gql.types'

export type RenderableMenuItem = Pick<
  GuestMenuItem,
  | 'id'
  | 'slug'
  | 'name'
  | 'priceData'
  | 'image'
  | 'isPopular'
  | 'description'
  | 'dietaryTags'
  | 'filterTags'
  | 'rewardPrice'
>

export function isRenderableMenuItem(item: any): item is RenderableMenuItem {
  return (
    typeof item === 'object' &&
    typeof item.id === 'number' &&
    typeof item.slug === 'string' &&
    typeof item.name === 'string' &&
    typeof item.priceData === 'object' &&
    typeof item.image === 'string' &&
    typeof item.isPopular === 'boolean' &&
    typeof item.description === 'string' &&
    Array.isArray(item.dietaryTags) &&
    Array.isArray(item.filterTags) &&
    typeof item.rewardPrice === 'number'
  )
}

export type ValidDietaryFilters = 'V' | 'VE' | 'GF'

export type LangNameProps = Pick<
  MenuItemPartsFragment,
  'name' | 'namefr' | 'nameel' | 'namees' | 'nameit' | 'namezhCN' | 'namede'
>

export function getName(
  menuItem: LangNameProps,
  language: ValidLanguageFilters,
) {
  let name = menuItem?.name
  switch (language) {
    case 'es':
      name = menuItem?.namees
      break
    case 'zh':
      name = menuItem?.namezhCN
      break
    case 'el':
      name = menuItem?.nameel
      break
    case 'fr':
      name = menuItem?.namefr
      break
    case 'it':
      name = menuItem?.nameit
      break
    case 'de':
      name = menuItem?.namede
      break
    default:
      name = menuItem?.name
      break
  }
  return name || menuItem?.name
}

export type LangDescProps = Pick<
  MenuItemDetailsFragment,
  | 'description'
  | 'descriptionPlain'
  | 'descriptionfr'
  | 'descriptionel'
  | 'descriptiones'
  | 'descriptionit'
  | 'descriptionzhCN'
  | 'descriptionde'
>

export function getDescription(
  menuItem: LangDescProps,
  language: ValidLanguageFilters,
) {
  switch (language) {
    case 'es':
      return menuItem?.descriptiones || menuItem?.descriptionPlain
    case 'zh':
      return menuItem?.descriptionzhCN || menuItem?.descriptionPlain
    case 'el':
      return menuItem?.descriptionel || menuItem?.descriptionPlain
    case 'fr':
      return menuItem?.descriptionfr || menuItem?.descriptionPlain
    case 'it':
      return menuItem?.descriptionit || menuItem?.descriptionPlain
    case 'de':
      return menuItem?.descriptionde || menuItem?.descriptionPlain
    default:
      return menuItem?.descriptionPlain
  }
}

export const getLangPropsFromMenuItem = (
  menuItem: GuestMenuItem | MenuItemDetailsFragment,
): LangDescProps => {
  const {
    description,
    descriptionPlain,
    descriptionfr,
    descriptionel,
    descriptiones,
    descriptionit,
    descriptionzhCN,
    descriptionde,
  } = menuItem

  return {
    description,
    descriptionPlain,
    descriptionfr,
    descriptionel,
    descriptiones,
    descriptionit,
    descriptionzhCN,
    descriptionde,
  }
}

/**
 * Repeat a value t times and return an array
 * @param t number of times to repeat the value
 * @param value value to repeat
 */
export function x<T>(t: number, value: T): T[] {
  return Array.from({ length: t }, () => value)
}

export type CalculateItemQuantityArgs = {
  upsellSelection: { quantity: number }
  upsellGroup?: Pick<GuestUpsellGroup, 'maxQuantity'> | null
  totalSelected: number
}

export type ItemQuantity = {
  minQuantity: number
  maxQuantity: number
}

export const DEFAULT_MAX_QUANTITY = 99

export const calculateItemQuantity = ({
  upsellGroup,
  upsellSelection,
  totalSelected,
}: CalculateItemQuantityArgs): Pick<ItemQuantity, 'maxQuantity'> => {
  /*
      This logic is weird because of two reasons:
      #1 - We need to dynamically set a max quantity based
        on how many upsells are already selected in the group and the maxQuantity.
      #2 - When you click into a selected upsell it shows the current selected quantity for that upsell.
      Submitting the same quantity again will not increase the selected count so we need to add this extra
      available quantity to the total selection count quantity across the upsells in the group.
    */
  const maxItemsLeft =
    (upsellGroup?.maxQuantity || DEFAULT_MAX_QUANTITY) - totalSelected
  const maxQuantity = maxItemsLeft + upsellSelection.quantity

  return {
    maxQuantity,
  }
}

export const getCombinedItemsTotalQuantity = (
  items: Array<{ quantity: number }>,
) =>
  items.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.quantity
  }, 0)
