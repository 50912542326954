import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import { useFlag } from '@unleash/proxy-client-react'
import Cookies from 'js-cookie'
import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from 'urql'

import {
  FLAG_REWARD_GUEST_VOUCHERS,
  FLAG_REWARDS_BALANCE,
  FLAG_REWARDS_PRICE,
} from '@/common/constants'
import { AuraMemberShipCookie } from '@/components/Rewards/hooks/useAuraOAuth'
import {
  GuestVoucher,
  LoyaltyPointsDocument,
  MenuDocument,
} from '@/gql/graphql'

import { useOrderingTypeContext, useVenueContext } from './VenueOrderContext'

const REWARD_CATEGORY = 'rewards'

export interface RewardContextValue {
  flags: {
    rewardsBalanceEnabled: boolean
    rewardsPriceEnabled: boolean
    guestVouchersEnabled: boolean
  }
  rewardsCategory: string
  balance: number | null
  noun: string
  fetching: boolean
  pricingReference: { isLoading: boolean; data: Record<string, number> }
  vouchers: {
    data: Pick<
      GuestVoucher,
      'id' | 'name' | 'description' | 'barcode' | 'imgSrc'
    >[]
    fetching: boolean
  }
}

export const defaultRewardsContextValue = {
  flags: {
    rewardsBalanceEnabled: false,
    rewardsPriceEnabled: false,
    guestVouchersEnabled: false,
  },
  rewardsCategory: REWARD_CATEGORY,
  balance: null,
  noun: 'Points',
  fetching: false,
  pricingReference: { isLoading: false, data: {} },
  vouchers: { data: [], fetching: false },
}

export const RewardsContext = createContext<RewardContextValue>(
  defaultRewardsContextValue,
)

export const RewardsProvider = ({ ...props }: PropsWithChildren<{}>) => {
  const { formatMessage } = useIntl()
  const { venueSlug } = useVenueContext()
  const { orderingType } = useOrderingTypeContext()

  const flags = {
    rewardsBalanceEnabled: useFlag(FLAG_REWARDS_BALANCE),
    rewardsPriceEnabled: useFlag(FLAG_REWARDS_PRICE),
    guestVouchersEnabled: useFlag(FLAG_REWARD_GUEST_VOUCHERS),
  }

  const isLoyaltyEnabled = useMemo(
    () => flags.rewardsBalanceEnabled || flags.guestVouchersEnabled,
    [flags.guestVouchersEnabled, flags.rewardsBalanceEnabled],
  )

  const [rewardResult, reexecuteQuery] = useQuery({
    query: LoyaltyPointsDocument,
    variables: {
      venueSlug,
      externalMemberId:
        Cookies.get(AuraMemberShipCookie + '-' + venueSlug) || null,
    },
    pause: isServer || !isLoyaltyEnabled,
  })

  useEffect(() => {
    if (rewardResult.fetching || !isLoyaltyEnabled) return

    const timerId = setTimeout(() => {
      reexecuteQuery({ requestPolicy: 'network-only' })
    }, 15 * 1000)

    return () => {
      clearTimeout(timerId)
    }
  }, [
    isLoyaltyEnabled,
    rewardResult.fetching,
    rewardResult.data,
    reexecuteQuery,
  ])

  const [menuResult] = useQuery({
    query: MenuDocument,
    pause: isServer || !isLoyaltyEnabled,
    variables: {
      venueSlug,
      categorySlug: 'rewards',
      orderingType,
      tableNumber: undefined,
      tableArea: undefined,
      orderingWindowStartDate: undefined,
      priceLevel: undefined,
    },
  })

  const referenceData = useMemo(() => {
    if (!flags.rewardsBalanceEnabled && !flags.rewardsPriceEnabled) return {}

    return (
      menuResult.data?.guestMenuCategory.menuSections.reduce((acc, curr) => {
        const sectionDict = curr.menuItems.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: (curr.rewardPrice?.originalAmountInCents || 0) * 0.01,
          }
        }, {})
        return { ...acc, ...sectionDict }
      }, {}) || {}
    )
  }, [
    menuResult.data?.guestMenuCategory.menuSections,
    flags.rewardsBalanceEnabled,
    flags.rewardsPriceEnabled,
  ])

  return (
    <RewardsContext.Provider
      value={{
        ...defaultRewardsContextValue,
        flags,
        balance: rewardResult.data?.loyaltyDetails?.guestData?.points || 0,
        noun:
          rewardResult.data?.loyaltyDetails?.metadata?.pointName ||
          formatMessage({ defaultMessage: 'points', id: 'KYUOY2' }),
        fetching: rewardResult.fetching,
        vouchers: {
          data: rewardResult.data?.loyaltyDetails?.vouchers || [],
          fetching: rewardResult.fetching,
        },
        pricingReference: {
          isLoading: menuResult.fetching,
          data: referenceData,
        },
      }}
      {...props}
    />
  )
}
